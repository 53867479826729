/**
 * Declination of plural forms
 *
 * @param {number} n quantity
 * @param {array} textForms declination variants [1, 2-4, >4], e.g. ['яблоко', 'яблока', 'яблок']
 * @return {string} correct plural form
 */
const pluralForm = (n, textForms) => {
  const tens = Math.abs(n) % 100
  const units = tens % 10

  if (tens > 10 && tens < 20) {
    return textForms[2]
  }

  if (units > 1 && units < 5) {
    return textForms[1]
  }

  if (units === 1) {
    return textForms[0]
  }

  return textForms[2]
}

/**
 * Extract time in HH:MM format from given date string
 *
 * @param {string} start Date string in ISO 8601 Datetime format, e.g. "2019-05-30T15:00:00Z"
 * @return {string} time in HH:MM format
 */
const formatTime = (start) => {
  start = new Date(start)
  return `${start.getUTCHours()}:${start.getUTCMinutes() < 10 ? 0 : ''}${start.getUTCMinutes()}`
}

/**
 * Extract date in locale date format from given date string
 *
 * @param {string} dateStr Date string in ISO 8601 Date format, e.g. "2019-05-30"
 * @return {string} date in "Четверг, 30 мая" format
 */
const formatDate = (...dateStr) => new Date(...dateStr).toLocaleDateString('ru-RU', { weekday: 'long', month: 'long', day: 'numeric' })

/**
 * Makes sure that a given function is only called once in a specified timeframe.
 *
 * @param {Function} fn - function to call after timeout
 * @param {Number} delay - timeout in ms
 * @return {function} Throttled function
 */
const throttle = (fn, delay) => {
  let wait = false

  return () => {
    if (!wait) {
      fn()
      wait = true

      setTimeout(() => { wait = false }, delay)
    }
  }
}

export default {
  pluralForm,
  formatTime,
  formatDate,
  throttle
}
