import axios from 'axios'
import 'promise-polyfill/src/polyfill'

/**
 * Get all the seminars from server
 *
 * @param {String} branch - filial's branch slug
 * @return {Promise} fetched seminars from server
 */
const getSeminars = ({ branch, apiSrc }) => axios
  .get(`${apiSrc}/api/v1/branches/${branch}/seminars?expand=properties`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.warn(error)
    return { data: { _embedded: { items: [] } } }
  })

export default {
  getSeminars
}

/*
const getSeminars = ({ branch }) => Promise.resolve({
  'code': 200,
  'status': 'Success',
    '_links': {
      'self': {
        'href': '/api/v1/branches/astana/seminars?expand=properties'
      }
    },
    '_embedded': {
      'items': [
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190516-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190516-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-05-21T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190523-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190523-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-05-23T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190530-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190530-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-05-30T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190606-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190606-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-06-06T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190613-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190613-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-06-13T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190620-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190620-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-06-20T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190627-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190627-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-06-27T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190704-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190704-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-07-04T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190711-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190711-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-07-11T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190718-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190718-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-07-18T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190725-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190725-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-07-25T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190801-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190801-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-08-01T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190808-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190808-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-08-08T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190815-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190815-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-08-15T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190822-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190822-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-08-22T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190829-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41?expand=properties'
            }
          },
          'id': '20190829-180000-1a4d0260-8e8d-47bb-9980-bccd5b555a41',
          'title': 'Азбука инвестирования',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Вводный семинар',
          'category_type': 'is',
          'description': '<p>Успешными финансистами не рождаются, ими становятся. Узнайте о том, как сделать первые шаги в мире финансовых рынков на вводном семинаре FOREX CLUB. Вы поймёте, как извлекают прибыль при торговле на рынках валют, акций и товаров. Узнаете о том, как ограничивать свои риски и планировать прибыль. Получите перечень конкретных действий, которые, в последствии, позволят вам стать профессиональным трейдером.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-08-29T18:00:00Z',
          'duration': 30
        },
        {
          '_links': {
            'self': {
              'href': '/api/v1/seminars/20190530-150000-432a30d9-af9a-4833-86ec-e834906a8d02?expand=properties'
            }
          },
          'id': '20190530-150000-432a30d9-af9a-4833-86ec-e834906a8d02',
          'title': 'Торговая система \'Элементарно, Ватсон!\'',
          'branch_name': 'Астана',
          'branch_slug': 'astana',
          'fxbankId': 274,
          'category_name': 'Базовый курс',
          'category_type': 'bc',
          'description': '<p>На данном мастер-классе Вы научитесь находить торговые сигналы для торговли на финансовом рынке и изучить торговые стратегии от ведущих аналитиков компании!</p>\r\n\r\n<p>Обязательна предварительная запись по контактным телефонам.</p>',
          'address': '010000, Казахстан, Астана, ул. Иманова, 13, офис 513, 5 этаж, БЦ \'Нурсаулет-2\'',
          'start': '2019-05-30T15:00:00Z',
          'duration': 30
        }
      ]
    },
    'count': 17
  }
})
*/
