<template>
  <div class="seminar-details">
    <p class="seminar-details__category">{{ seminar.category_name }}</p>
    <div class="seminar-details__description" v-html="seminar.description"></div>
    <p class="seminar-details__time">
      <b>Начало:</b> в <span title="Время проведения местное">{{ shortTime(seminar.start) }}</span>
    </p>
    <p class="seminar-details__duration">
      <b>Длительность:</b> {{ seminar.duration }} {{ getPluralMinutes(seminar.duration) }}
    </p>
    <p class="seminar-details__address" v-if="seminar.address">
      <b>Адрес:</b> {{ seminar.address }}
    </p>
    <p class="seminar-details__link" v-if="seminar.link_href">
      <b>Ссылка: </b> <a v-bind:href="seminar.link_href">{{ seminar.link_name ? seminar.link_name : seminar.link_href }}</a>
    </p>

    <!-- linkName and linkHref -->
  </div>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'SeminarDetails',

  props: {
    seminar: {
      required: true,
      type: Object
    }
  },

  computed: {
    shortTime () {
      return utils.formatTime
    },

    getPluralMinutes () {
      return (count) => utils.pluralForm(count, ['минута', 'минуты', 'минут'])
    }
  }
}
</script>

<style scoped>
.seminar-details {
  font-size: 12px;
}
[data-size*="md"] .seminar-details {
  overflow: auto;
  height: 100%;
  margin-right: 10px;
  padding-right: 10px;
  order: 1;
  font-size: 13px;
}

/deep/ p {
  margin: 0 0 0.5em;
}

.seminar-details__description >>> img {
  max-width: 100%;
  height: auto !important;
}

.seminar-details__category {
  font-weight: 600;
}

.seminar-details__time span {
  border-bottom: 1px dotted #2c3e50;
  cursor: help;
}

.seminar-details__link a {
  color: #0083d0;
  text-decoration: underline;
}
</style>
