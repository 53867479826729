<template>
  <div class="seminars-calendar-container" :data-popup="showForm">
    <Navigation
      :month="month"
      :year="year"
      @showMonth="showMonth"/>
    <Days v-if="!showForm"/>
    <Month
      v-if="!showForm"
      :month="month"
      :year="year"
      :seminars="seminars"
      @setDay="setDay" />
    <RegistrationModal
      v-if="showForm"
      :day="selectedDay"
      :apiKey="apikey"
      :accHost="acchost"
      :landingLibURL="landingliburl"
      @closeForm="closeForm" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Days from '@/components/Days.vue'
import Month from '@/components/Month.vue'
import RegistrationModal from '@/components/RegistrationModal'
import api from '@/api'
import utils from '@/utils'
import 'promise-polyfill/src/polyfill'

export default {
  name: 'SeminarsCalendar',

  props: {
    branch: {
      type: String,
      required: true
      // default: 'shimkent'
    },
    apikey: {
      type: String,
      required: true
      // default: 'd794d0fa4911756d3ab9282171706800c6896b33'
    },
    branchmedia: {
      type: String,
      default: 'https://branchmedia.fxclub.org'
    },
    acchost: {
      type: String,
      default: 'https://api-account.fxclub.org/v1'
    },
    landingliburl: {
      type: String,
      default: 'https://lib.fxclub.org/landing/js/landing-api.min.2.2.1.js'
    }
  },

  components: {
    Navigation,
    Days,
    Month,
    RegistrationModal
  },

  data () {
    return {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      seminars: [],
      selectedDay: {},
      showForm: false
    }
  },

  methods: {
    getData () {
      api.getSeminars({ branch: this.branch, apiSrc: this.branchmedia })
        .then(data => {
          this.seminars = data.data._embedded.items
        })
    },

    closeForm () {
      this.showForm = false
    },

    setDay (day) {
      this.$children[this.$children.length - 1].currentSeminar = false
      this.selectedDay = day
      this.showForm = true
    },

    showMonth (direction) {
      switch (direction) {
        case 'next':
          direction = 1
          break
        case 'previous':
          direction = -1
          break
      }

      const month2set = new Date(this.year, this.month + direction)

      this.year = month2set.getFullYear()
      this.month = month2set.getMonth()
      this.showForm = false
    },

    updateWidth () {
      const width = this.$el.offsetWidth

      this.$el.dataset.size = width > 650 ? 'md lg' : width > 500 ? 'md' : 'sm'
    }
  },

  created () {
    this.getData()

    window.addEventListener('resize', utils.throttle(() => {
      this.updateWidth()
    }, 50))
  },

  mounted () {
    this.updateWidth()
  }
}
</script>

<style>
.seminars-calendar-container {
  position: relative;
  color: #2c3e50;
  font-family: Arial,Tahoma,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
