<template>
  <div class="week">
    <div class="week__day" v-for="day in week" :key="day.shortName">
      <span class="week__day--name">{{ day.name }}</span>
      <span class="week__day--short-name">{{ day.shortName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Days',

  data () {
    return {
      week: [
        {
          name: 'Понедельник',
          shortName: 'Пн'
        },
        {
          name: 'Вторник',
          shortName: 'Вт'
        },
        {
          name: 'Среда',
          shortName: 'Ср'
        },
        {
          name: 'Четверг',
          shortName: 'Чт'
        },
        {
          name: 'Пятница',
          shortName: 'Пт'
        },
        {
          name: 'Суббота',
          shortName: 'Сб'
        },
        {
          name: 'Воскресенье',
          shortName: 'Вс'
        }
      ]
    }
  }
}
</script>

<style scoped>
.week {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-left: 1px solid #9e9d98;
}

.week__day {
  width: calc(100% / 7);
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #9e9d98;
  border-right: 1px solid #9e9d98;
  color: #adadad;
  font-size: 10px;
  font-weight: bold;
  text-align: center;

  &:nth-child(n + 6) {
    color: #d78080;
  }
}
[data-size*="md"] .week__day {
  padding: 10px;
  text-align: left;
}

.week__day--name {
  display: none;
}
[data-size="md lg"] .week__day--name {
  display: block;
}

.week__day--short-name {
  display: block;
  font-style: normal;
}
[data-size="md lg"] .week__day--short-name {
  display: none;
}
</style>
