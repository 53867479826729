/**
 * Return week day of a first day of given month
 *
 * @param {number} year - requested year
 * @param {number} month - requested month, 0..11
 * @returns {number} 0..6 for Sunday..Satarday
 */
const firstDay = (year, month) => (new Date(year, month)).getDay()

/**
 * Return the number of days in given month
 *
 * @param {number} year - requested year
 * @param {number} month - requested month, 0..11
 * @returns {number} e.g. 30 for November
 */
const daysInMonth = (year, month) => 32 - new Date(year, month, 32).getDate() // number of days of given month

/**
 * Return calendar grid for a given month
 *
 * @param {number} year - requested year
 * @param {number} month - requested month, 0..11
 * @returns {Array} array of days for requested month, from first month's week Monday till last month's week Sunday
 */
const getCalendarGrid = ({ year, month }) => {
  const monthGrid = []
  const prevMonth = new Date(year, month - 1)
  const nextMonth = new Date(year, month + 1)

  // populate all the days of given month
  for (let i = firstDay(year, month) - 1, j = 1; j <= daysInMonth(year, month); i++, j++) {
    monthGrid[i] = {
      date: j,
      day: (i + 1) % 7,
      month: month,
      year: year,
      seminars: []
    }
  }

  // append next month's days in last week
  if (nextMonth.getDay() !== 1) {
    for (let i = monthGrid[monthGrid.length - 1].day, j = 1; i < 7; i++, j++) {
      monthGrid.push({
        date: j,
        day: (i + 1) % 7,
        month: nextMonth.getMonth(),
        year: nextMonth.getFullYear(),
        seminars: []
      })
    }
  }

  // prepend previous month's days in first week
  for (let i = firstDay(year, month) - 2, j = daysInMonth(prevMonth.getFullYear(), prevMonth.getMonth()); i > -1; i--, j--) {
    monthGrid[i] = {
      date: j,
      day: (i + 1) % 7,
      month: prevMonth.getMonth(),
      year: prevMonth.getFullYear(),
      seminars: []
    }
  }

  return monthGrid
}

export default getCalendarGrid
