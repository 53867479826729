<template>
  <div class="calendar">
    <div v-for="day in days"
        :key="`${day.date}.${day.month}`"
        :class="dayClass(day)">
      <a v-if="day.seminars.length"
         class="calendar__link"
         @click="$emit('setDay', day)">
        <strong class="calendar__date">{{ day.date }}</strong>
        <small class="calendar__seminars">{{day.seminars.length}}<span>&nbsp;{{ getSeminarsCaption(day.seminars.length) }}</span></small>
      </a>
      <span v-else class="calendar__empty-date">
        <strong class="calendar__date">{{ day.date }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
import monthGrid from '@/utils/calendar.js'
import utils from '@/utils'

export default {
  name: 'Month',

  props: {
    month: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      required: true
    },
    seminars: {
      type: Array
    }
  },

  data () {
    return {}
  },

  computed: {
    days () {
      return this.populateMonthGrid({
        year: this.year,
        month: this.month,
        seminars: this.seminars
      })
    },

    dayClass () {
      return (day) => {
        return [
          'calendar__day',
          { 'calendar__day--other': day.month !== this.month },
          { 'calendar__day--weekend': day.day === 6 || day.day === 0 },
          { 'calendar__day--today': this.isToday({ day: day }) }
        ]
      }
    },

    getSeminarsCaption () {
      return (count) => utils.pluralForm(count, ['семинар', 'семинара', 'семинаров'])
    }
  },

  methods: {
    populateMonthGrid: ({ year, month, seminars }) => {
      const populatedDays = monthGrid({ year: year, month: month })

      seminars.forEach(seminar => {
        const day2populate = populatedDays.find(day => {
          const start = new Date(seminar.start)

          return start.getUTCDate() === day.date &&
                 start.getUTCMonth() === day.month &&
                 start.getUTCFullYear() === day.year
        })

        if (day2populate) day2populate.seminars.push(seminar)
      })

      populatedDays.forEach(day => {
        if (day.seminars.length) {
          day.seminars.sort((a, b) => {
            return new Date(a.start).getTime() - new Date(b.start).getTime()
          })
        }
      })

      return populatedDays
    },

    isToday: ({ day }) => {
      const now = new Date()

      return now.getDate() === day.date &&
             now.getMonth() === day.month &&
             now.getFullYear() === day.year
    }
  }
}
</script>

<style scoped>
.calendar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 225px;
  margin: 0;
  padding: 0;
  border-left: 1px solid #9e9d98;
  box-sizing: border-box;
}
[data-size*="md"] .calendar {
  min-height: 350px;
}

.calendar__day {
  position: relative;
  width: calc(100% / 7);
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #9e9d98;
  border-right: 1px solid #9e9d98;
  color: #adadad;
}
[data-size*="md"] .calendar__day {
  min-height: 70px;
}

.calendar__day--other > * {
  opacity: .25;
}

.calendar__day--today {
  background: #eee;
}

.calendar__day--weekend {
  color: #d78080;
}

.calendar__link,
.calendar__empty-date {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 11px;
  text-align: center;

}
[data-size*="md"] .calendar__link,
[data-size*="md"] .calendar__empty-date {
  padding: 10px;
  text-align: left;
}

.calendar__date {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;

}
[data-size*="md"] .calendar__date {
  margin: 0 0 13px;
}

.calendar__link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #eff2f5;
  }

  & .calendar__date {
    margin-bottom: -2px;
    border-bottom: 2px solid #58595b;
    color: #58595b;
  }
}
[data-size*="md"] .calendar__link .calendar__date {
  margin: 0 0 13px;
}

.calendar__seminars {
  position: absolute;
  top: 5px;
  right: 2px;
  color: #fff;
  background: #f00;
  width: 14px;
  line-height: 14px;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;

  & > span {
    display: none;
    color: #626365;
  }
}
[data-size*="md"] .calendar__seminars {
  display: block;
  position: static;
  width: unset;
  background: none;
  color: #626365;
  font-weight: normal;
  line-height: unset;
  text-align: left;
}
[data-size*="md"] .calendar__seminars > span {
  display: inline;
}

.calendar__day--weekend {
  & .calendar__link {
    & .calendar__date {
      border-bottom: 2px solid #b00000;
      color: #b00000;
    }
  }
}
</style>
