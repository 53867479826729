<template>
  <div class="seminars__container">
    <h2 class="seminars__header">{{ showDate(day.year, day.month, day.date) }}</h2>

    <div class="seminars__list">
      <div v-for="seminar in day.seminars"
          :key="seminar.id"
          class="seminars__item">
        <span class="seminars__time">{{ shortTime(seminar.start) }}</span>
        <a @click="$emit('selectSeminar', seminar)" class="seminars__link">{{ seminar.title }}</a>

        <div class="seminars__description" v-html="seminar.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'SeminarsList',

  props: {
    day: {
      required: true,
      type: Object
    }
  },

  data () {
    return {}
  },

  computed: {
    shortTime () {
      return utils.formatTime
    },

    showDate () {
      return utils.formatDate
    }
  }
}
</script>

<style scoped>
.seminars__container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.seminars__header {
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: 0.85em;
  color: #2c3e50;
  font-size: 20px;
  font-weight: normal;

  &:first-letter {
    text-transform: uppercase;
  }
}

.seminars__list {
  overflow: auto;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.seminars__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1em;
}

.seminars__time {
  flex-basis: 5.5ch; /* 1ch is relative to width of the "0" simbol */
}

.seminars__link {
  flex-basis: calc(100% - 5.5ch);
  color: #0083d0;
  text-decoration: underline;
  cursor: pointer;
}

.seminars__description {
  display: none;
  width: 100%;
  margin: 0.5em 0 1em;

  & >>> p {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & >>> img {
    max-width: 100%;
    height: auto !important;
  }
}
[data-size*="md"] .seminars__description {
  display: block;
}
</style>
