<template>
  <div class="registration-modal">
    <RegistrationForm
      v-if="currentSeminar"
      :seminar="currentSeminar"
      :apiKey="apiKey"
      :accHost="accHost"
      :landingLibURL="landingLibURL"
      @selectSeminar="selectSeminar" />
    <SeminarsList
      v-else
      :day="day"
      @selectSeminar="selectSeminar" />

    <button class="register__x" @click="$emit('closeForm')">×</button>
  </div>
</template>

<script>
import SeminarsList from '@/components/RegistrationModal/SeminarsList.vue'
import RegistrationForm from '@/components/RegistrationModal/RegistrationForm.vue'

export default {
  name: 'RegistrationModal',

  props: {
    day: {
      required: true,
      type: Object
    },
    apiKey: {
      type: String,
      required: true
    },
    accHost: {
      type: String,
      required: true
    },
    landingLibURL: {
      type: String,
      required: true
    }
  },

  components: {
    SeminarsList,
    RegistrationForm
  },

  data () {
    return {
      currentSeminar: this.day.seminars.length === 1 ? this.day.seminars[0] : false
    }
  },

  methods: {
    selectSeminar (seminar) {
      this.currentSeminar = seminar
    }
  }
}
</script>

<style scoped>
.registration-modal {
  position: relative;
  min-height: 257px;
  padding: 20px;
  background: #fff;
  border: 1px solid #9e9d98;
  border-top: 0;
  box-sizing: border-box;
}
[data-size*="md"] .registration-modal {
  min-height: 382px;
}

.register__x {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 55px;
    padding: 0 17px;
    box-sizing: content-box;
    background: none;
    border: none;
    opacity: .5;
    font-size: 30px;
    line-height: 55px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &:active,
    &:focus {
      top: 1px;
    }
}
</style>
