/**
 * Add script tag with given uri and fire callback after loading
 *
 * @param {String} url address of script to be loaded
 * @param {function} callback function to be fired
 */
const loadScript = ({ url, callback, params }) => {
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.src = url

  if (typeof callback === 'function') {
    s.onload = s.onreadystatechange = (event) => {
      event = event || window.event
      if (event.type === 'load' || (/loaded|complete/.test(s.readyState))) {
        s.onload = s.onreadystatechange = null

        if (params !== undefined) {
          callback(params)
        } else {
          callback()
        }
      }
    }
  }

  document.body.appendChild(s)
}

/**
 * Initialize landing, with loading the library if required
 *
 * @param {String} formId CSS selector for the form
 * @param {String} apiKey Landing-API key
 * @param {String} accHost Landing-API accHost
 * @param {String} landingLibURL Landing-API URL
 */
const initLanding = ({ formId, apiKey, accHost, landingLibURL }) => {
  let landingInstance

  const callback = () => {
    landingInstance = window.fxcLanding.create({
      form: formId,
      apiKey: apiKey,
      accHost: accHost,
      registrationCallback: function (data, next) {
        data.landing.$form.classList.add('is-complete')
        next()
      }
    })
  }

  if (window.fxcLanding === undefined) {
    loadScript({ url: landingLibURL, callback: callback })
  } else {
    callback()
  }

  return landingInstance
}

export default { initLanding }
