<template>
  <div class="registration-block">
    <h2 class="registration-block__seminar-title">{{ seminar.title }}</h2>
    <a class="registration-block__back" @click="$emit('selectSeminar', false)">
      &laquo; {{ showDate(seminar.start) }}
    </a>

    <div class="registration-block__container">
      <div class="registration-form">
        <form id="registration-form" method="POST">
          <input type="hidden" name="seminarId" :value="seminar.id" />

          <div class="registration-form__row">
            <input id="registration-form__firstName" type="text" class="registration-form__control" name="firstName" placeholder="Ваше имя">
          </div>

          <div class="registration-form__row">
            <input id="registration-form__email" type="email" class="registration-form__control" name="email" placeholder="Email">
          </div>

          <div class="registration-form__row">
            <input id="registration-form__phone" type="text" class="registration-form__control" name="phone" placeholder="Ваш телефон">
          </div>

          <script class="fxc-captcha-template" type="text/template">
            <div class="fxc-captcha-container registration-form__row">
              <div class="registration-form__captcha">
                <button class="fxc-captcha-refresh" type="button">
                  <img class="fxc-captcha-image" alt="Captcha">
                </button>
                <div class="fxc-captcha-input-container">
                  <input placeholder="Введите код"
                      name="captcha"
                      type="text"
                      class="registration-form__control"
                      id="registration-form__captcha"
                      autocomplete="off">
                </div>
              </div>
              <div class="fxc-error-msg-container fxc-hidden" data-fxc-for="captcha"></div>
            </div>
          </script>

          <div class="registration-form__row">
            <input type="submit" class="registration-form__submit" value="Зарегистрироваться">
          </div>

          <div class="registration-form__success">
            <h3 class="registration-form__success-header">Поздравляем!</h3>
            <p class="registration-form__success-text">Вы успешно зарегистрировались на {{ seminar.category_name }}.</p>
          </div>
        </form>
      </div>

      <SeminarDetails :seminar="seminar" />
    </div>
  </div>
</template>

<script>
import SeminarDetails from '@/components/RegistrationModal/SeminarDetails'
import utils from '@/utils'
import landing from '@/utils/landing.js'

export default {
  name: 'RegistrationModal',

  props: {
    seminar: {
      required: true,
      type: Object
    },
    apiKey: {
      type: String,
      required: true
    },
    accHost: {
      type: String,
      required: true
    },
    landingLibURL: {
      type: String,
      required: true
    }
  },

  components: {
    SeminarDetails
  },

  data () {
    return {
    }
  },

  methods: {
    showDate (timeUTC) {
      const now = new Date(timeUTC)
      return utils.formatDate(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    }
  },

  mounted () {
    landing.initLanding({
      formId: '#registration-form',
      apiKey: this.apiKey,
      accHost: this.accHost,
      landingLibURL: this.landingLibURL
    })
  }
}
</script>

<style scoped>
.registration-block {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.registration-block__seminar-title {
  margin: 0;
  padding-right: 15px;
  color: #58595b;
  font-size: 20px;
  font-weight: normal;
}

.registration-block__back {
  color: #0083d0;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.registration-block__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 1em;
  padding-right: 5px;
}
[data-size*="md"] .registration-block__container {
  flex-direction: row;
  padding: 0;
}

.registration-form {
  min-width: 220px;
  font-size: 12px;

  & > form {
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    background: #f6f6f6;
  }
}
[data-size*="md"] .registration-form {
  order: 2;
}

/deep/ .registration-form__row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

/deep/ .registration-form__control {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .registration-form__captcha {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
}

/deep/ .fxc-captcha-refresh {
  width: 122px;
  max-width: 50%;
  height: 30px;
  margin-right: 5px;
  padding: 0;
  flex-shrink: 0;
  background: none;
  border: none;
}

/deep/ .fxc-captcha-image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/deep/ .fxc-hidden {
  display: none;
}

/deep/ .fxc-error-msg {color: #f00;}

/deep/ .fxc-common-errors {
  margin: 0;
  padding: 0;
  list-style: none;
  color: red;

  & li {margin-bottom: 10px;}
}

/deep/ .is-complete::before,
/deep/ .fxc-pending::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255,0.25);
}
/deep/ .fxc-pending::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 32px;
  height: 32px;
  border: 4px solid;
  border-color: transparent #f60;
  border-radius: 50%;
  animation: rotate ease 0.7s infinite;
}

@keyframes rotate {
  0%   {transform:rotate(0deg);}
  100% {transform:rotate(180deg);}
}

.registration-form__submit {
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 8px 16px;
  border: 1px solid #f60;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #f70;
  background-image: linear-gradient(to bottom, #f80, #f60);
  vertical-align: middle;
  color: #fff;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.registration-form__success {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0,0,0,0.25);
  text-align: center;
}

.registration-form__success-header {margin-top: 0}

.registration-form__success-text {margin-bottom: 0;}

.is-complete .registration-form__success {
  display: block;
}
</style>
