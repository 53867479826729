<template>
  <div class="nav">
    <div class="nav__arrow nav__arrow--prev">
      <a v-if="showPreviousMonthLink"
         class="nav__link"
         @click="$emit('showMonth', 'previous')">&larr;</a>
    </div>
    <div class="nav__name">
      <strong>{{ months[month] }} {{ year }}</strong>
    </div>
    <div class="nav__arrow nav__arrow--next">
      <a class="nav__link"
         @click="$emit('showMonth', 'next')">&rarr;</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',

  props: {
    year: {
      type: Number,
      required: true
    },
    month: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
    }
  },

  computed: {
    showPreviousMonthLink () {
      return new Date().getTime() < new Date(this.year, this.month).getTime()
    }
  }
}
</script>

<style scoped>
.nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #d4d9de linear-gradient(to bottom, #f2f4f7, #d6dade);
  border: 1px solid #9e9d98;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  font-size: 19px;
  line-height: 40px;
}

.nav__arrow {
  width: calc(100% / 7);
  box-sizing: border-box;
}

.nav__name {
  flex-grow: 1;
  font-weight: bold;
  text-align: center;
}

.nav__arrow--prev {text-align: left;}

.nav__arrow--next {text-align: right;}

.nav__link {
  display: block;
  padding: 0 10px;
  text-decoration: none;
  color: #2c3e50;
  cursor: pointer;
}
</style>
